<template>
  <div class="container-fluid pb-5">
    <b-card class="af-card">
      <h2 class="page-title text-18 text-uppercase mb-4">Conversions</h2>
      <b-row>
        <b-col cols="6">
          <div class="bg-primary curved-edge">
            <b-row class="align-items-end justify-content-between text-white py-5 px-3">
              <b-col cols="5" class="border-right">
                <h3 class="text-30">₦ {{ totalAmount | format-currency }}</h3>
                <p class="text-uppercase text-14">Total Amount</p>
              </b-col>
              <b-col cols="7">
                <div class="row no-gutters align-items-center justify-content-between">
                  <div>
                    <h3 class="text-30">{{ totalConversions | format-currency }}</h3>
                    <p class="text-uppercase text-14">Total Conversions</p>
                  </div>
                  <img src="@/assets/images/convert.svg" width="43" height="43">
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="bg-black curved-edge py-5 px-3">
            <b-row v-if="networks.length" class="align-items-center text-uppercase">
              <b-col cols="8">
                <b-row>
                  <b-col v-for="(item, index) in networks" :key="item._id" cols="6">
                    <p class="mb-2">
                      <span class="emphasize font-weight-bold">{{ networks[index].networkName }} - </span>
                      <span class="text-white">{{ networks[index].networkRate }}%</span>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4">
                <b-button variant="outline-light" class="text-uppercase text-white" to="/change-rates">
                  Edit Network
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-between">
        <b-col cols="4" class="mt-4">
          <b-form-group class="af-input">
            <b-input-group class="mb-3">
              <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
              <template v-slot:append>
                <b-input-group-text class="af-input-append">
                  <span class="icon-magnifier"></span>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="text-right mt-4">
          <select v-model="sort" class="custom-select">
            <option value="" selected>Sort</option>
            <option value="approved">Approved</option>
            <option value="processing">Pending</option>
            <option value="declined">Declined</option>
          </select>
        </b-col>
      </b-row>
      <!-- table -->
      <b-table
        id="af-table"
        ref="table"
        :items="getConversions"
        :fields="fields"
        :perPage="aggregate"
        :currentPage="currentPage"
        hover
        responsive
        borderless
        show-empty
      >

        <template #empty>
          <p class="text-center text-muted">No conversions found</p>
        </template>

        <template v-slot:head()="data">
          <span class="text-uppercase title">{{ data.label }}</span>
        </template>

        <template v-slot:head(user)>
          <span class="text-uppercase title">Users</span>
        </template>

        <template v-slot:head(network_name)>
          <span class="text-uppercase title">Network</span>
        </template>

        <template v-slot:head(approved)>
          <span class="text-uppercase title">Status</span>
        </template>

        <template v-slot:cell(user)="data">
          <div class="row align-items-center no-gutters">
            <div class="text-uppercase mr-2">
              <span class="initials">{{ computeInitials(data.value.name) }}</span>
            </div>
            <div>
              <p class="mb-0">{{ data.value.name }}</p>
              <p class="text-muted text-14 mb-0">USER</p>
            </div>
          </div>
        </template>

        <template v-slot:cell(network_name)="data">
          <span class="text-uppercase">{{ data.value }}</span>
        </template>

        <template v-slot:cell(amount)="data">
          <span>₦{{ data.value || '' | format-currency }}</span>
        </template>

        <template v-slot:cell(number)="data">
          <span>0{{ data.value }}</span>
        </template>

        <template v-slot:cell(return_amount)="data">
          <span>₦{{ data.value || '' | format-currency }}</span>
        </template>

        <template v-slot:cell(approved)="data">
          <span v-if="data.item.status === 'approved'" class="text-success font-weight-bold text-uppercase">Approved</span>
          <span v-if="data.item.status === 'declined'" class="text-danger font-weight-bold text-uppercase">Declined</span>
          <b-button
            v-if="data.item.status === 'processing'"
            @click="processConversion('approve', data.item._id)"
            variant="outline-success"
            class="text-uppercase mr-2"
            size="sm"
          >
            Approve
          </b-button>
          <b-button
            v-if="data.item.status === 'processing'"
            @click="processConversion('decline', data.item._id)"
            variant="outline-danger"
            class="text-uppercase"
            size="sm"
          >
            Decline
          </b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="right"
        hide-ellipsis
        hide-goto-end-buttons
        aria-controls="af-table"
      >
        <template v-slot:page="{ page, active }">
          <span class="active" v-if="active">{{ page }}</span>
          <span v-else>{{ page }}</span>
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import { getAllConversions, approveConversion } from '../apis/conversions'
import { getNetworks } from '../apis/network'

export default {
  metaInfo: {
    title: 'Conversions',
    titleTemplate: '%s | AirtimeFlip'
  },
  data () {
    return {
      currentPage: 1,
      totalAmount: '',
      totalConversions: '',
      perPage: 25,
      filter: null,
      items: [],
      filteredItems: [],
      networks: [],
      fields: [
        'user',
        'orderId',
        'network_name',
        'number',
        'amount',
        'return_amount',
        'approved'
      ],
      selected: null,
      sort: '',
      aggregate: 25,
      timeout: ''
    }
  },
  computed: {
    rows () {
      return this.aggregate
    }
  },
  watch: {
    sort () {
      this.currentPage = 1
      this.$refs.table.refresh()
    },
    filter () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$refs.table.refresh()
        clearTimeout(this.timeout)
      }, 1000)
    }
  },
  created () {
    // this.getConversions()
    this.getNetworks()
  },
  methods: {
    computeInitials (name) {
      if (!name) return
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    async getConversions () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      let query = `page=${this.currentPage}`
      query += this.sort ? `&status=${this.sort}` : ''
      query += this.filter ? `&filter=true&query=${this.filter}` : ''
      try {
        const { data } = await getAllConversions(query)
        this.items = data.data
        this.aggregate = data.agreegate
        this.totalAmount = data.total
        this.totalConversions = data.count
        return data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getNetworks () {
      try {
        const { data } = await getNetworks()
        this.networks = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async processConversion (type, id) {
      const payload = {
        status: type
      }
      try {
        const { data } = await approveConversion(payload, id)
        const message = data.message ? 'Conversion declined' : 'Conversion approved'
        this.$bvToast.toast(message, {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getConversions()
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #3FBBC2;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
